
import { Component, Vue } from "vue-property-decorator";
import { readAdminUsers, readQRUser, readLanguage } from "@/store/admin/getters";
import { dispatchGetQRUser, dispatchGetUsers } from "@/store/admin/actions";
import {commitSwitchLanguage} from "@/store/admin/mutations";
import { readToken } from "@/store/main/getters";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { api } from "@/api";
import { IUserProfile } from "@/interfaces";

@Component
export default class AdminUsers extends Vue {
  public dialogDelete = false;
  public userID = -1;
  public user: IUserProfile = {id: -1, is_active: false, is_superuser: false, email: '', gender: '', postcode: -1, road: '', nationality: '', house_number: '', birthplace: '', birthday: new Date(), firstname: '', lastname: '', city: ''};
  public search = "";
  public dialogQR = false;
  public headers = [
    {
      text: "ID",
      sortable: true,
      value: "id",
      align: "left",
    },
    {
      text: "Email",
      sortable: true,
      value: "email",
      align: "left",
    },
    {
      text: "Full Name",
      sortable: true,
      value: "full_name",
      align: "left",
    },
    {
      text: "Role",
      sortable: true,
      value: "isSuperuser",
      align: "left",
    },
  ];
  public headers_german = [
    {
      text: "ID",
      sortable: true,
      value: "id",
      align: "left",
    },
    {
      text: "Email",
      sortable: true,
      value: "email",
      align: "left",
    },
    {
      text: "Vollständiger Name",
      sortable: true,
      value: "full_name",
      align: "left",
    },
    {
      text: "Funktion",
      sortable: true,
      value: "isSuperuser",
      align: "left",
    },
  ];
  public items_per_page = [25, 50, 100, {"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}];
  public pagination =  {
    rowsPerPage: 25,
  };

  get users() {
    return readAdminUsers(this.$store);
  }

  public getRole(isSuperuser: boolean) {
    if (isSuperuser) {
      return "Admin";
    } else if (this.language) {
      return "Nurse";
    }
    return "Tierpfleger*in";
  }

  public async deleteUserIni(id: number) {
    this.dialogDelete = true;
    this.userID = id;
  }
  get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }
  
  public iniDialogQR(profile: IUserProfile) {
    console.log("QR CODE");
    this.user = profile;
    dispatchGetQRUser(this.$store, profile.id);
    this.dialogQR = true;
  }

  public getQR() {
    return "data:image/jpeg;base64," + readQRUser(this.$store);
  }

  public async deleteUser() {
    await api
      .deleteUser(readToken(this.$store), this.userID)
      .catch((error) => console.log(error));
    await dispatchGetUsers(this.$store);
    this.dialogDelete = false;
  }

  public no() {
    this.dialogDelete = false;
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
  }

    public userAdress(profile: IUserProfile) {
    var adress = "";
    if(profile.road) {
      adress += profile.road;
    }
    if(profile.house_number) {
      adress += " " + profile.house_number + ", ";
    }
    if(profile.postcode) {
      adress += profile.postcode.toString() + " ";
    }
    if(profile.city) {
      adress += profile.city;
    }
    if(adress === "") return "-------";
    return adress;
  }

  public userName(profile: IUserProfile) {
    var name = "";
    if(profile.firstname) {
      name += profile.firstname + " ";
    }
    if(profile.lastname) {
      name += profile.lastname;
    }
    if(name === "") return "-------";
    return name;
  }

  public readEmail(profile: IUserProfile) {
    if(profile.email) return profile.email;
    return "-------";
  }

  public makeTable() {
    var table = new Array<any>();
    this.users.forEach((element) => {
      let arrayElement: string[] = [];
      arrayElement.push(element.id.toString());
      arrayElement.push(this.readEmail(element));
      arrayElement.push(this.userName(element));
      arrayElement.push(this.getRole(element.is_superuser));
      arrayElement.push(this.userAdress(element));
      table.push(Object.values(arrayElement));
    });
    return table;
  }

  public downloadQR() {
    var pdf = new jsPDF();
    var textPDF = '';
    pdf.setFontSize(25);;
    pdf.text("QR Code for Login", 65, 15);
    pdf.setFontSize(13);
    textPDF = "Name: ";
    if(this.user.firstname){
       textPDF += this.user.firstname + " ";
       }
    if(this.user.lastname) {
      textPDF += this.user.lastname + " ";
      }
   pdf.text(textPDF, 10, 30);
   pdf.text("User Email: " + this.user.email, 10, 36);
   pdf.addImage(this.getQR(), "JPEG", 3, 40, 200, 200);
   let name: string = '';
    if(this.user.firstname){
       name += "_" + this.user.firstname;
       }
    if(this.user.lastname) {
      name += "_" + this.user.lastname;
      }
   pdf.save("qrcode_user" + name + "_" + this.user.id);
  }

  public download() {
    var pdf = new jsPDF();
    var textPDF = '';
    pdf.setFontSize(25);
    textPDF = 'Full Users List';
      pdf.text(textPDF, 75, 15);
    if(this.users.length > 0){
      autoTable(pdf, {
        head: [["ID", "Email", "Name", "Role", "Adress"]],
        body: this.makeTable(),
        headStyles: {
          fillColor: [192, 0, 0],
        },
        startY: 40,
        theme: "striped",
      }); 
    }
    pdf.save('users.pdf');
  }

  public download_german() {
    var pdf = new jsPDF();
    var textPDF = '';
    pdf.setFontSize(25);
    textPDF = 'Vollständige Benutzerliste';
      pdf.text(textPDF, 55, 15);
    if(this.users.length > 0){
      autoTable(pdf, {
        head: [["ID", "Email", "Name", "Funktion", "Adresse"]],
        body: this.makeTable(),
        headStyles: {
          fillColor: [192, 0, 0],
        },
        startY: 40,
        theme: "striped",
      }); 
    }
    pdf.save('users.pdf');
  }
}
